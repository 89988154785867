const directives = {
    mounted(el, binding) {
        $(el).tooltip({
            title: binding.value,
            placement: binding.arg,
            trigger: 'hover'
        });
    },
    unmounted(el) {
        $(el).tooltip('dispose');  // To clean up the tooltip when the element is unmounted
    }
};

export default directives;