const TOPMENUITEMS = [
    {
        item: 'HOME',
        url: '/',
        isScrollable: true
    },
    {
        item: 'CHAPTERS',
        url: 'chapters',
        isScrollable: true
    },
    {
        item: 'MAPS',
        url: 'maps',
        isScrollable: true
    },
    {
        item: 'REPORTS',
        url: 'reports',
        isScrollable: true
    },
    {
        item: 'TOOLS',
        url: 'tools',
        isScrollable: true
    },
    {
        item: 'VAULT',
        url: 'vault',
        isScrollable: true
    }
];

export default {
	TOPMENUITEMS: TOPMENUITEMS
}