<template>
  <div class="home">
    <MainSec />
    <RoadMap />
    <Chapters />
    <Maps />
    <Reports />
    <Tools />
    <Vault />
  </div>
</template>

<script>
// @ is an alias to /src
import MainSec from '@/views/MainSec.vue'
import RoadMap from '@/views/RoadMap.vue'
import Chapters from '@/views/Chapters.vue'
import Maps from '@/views/Maps.vue'
import Reports from '@/views/Reports.vue'
import Tools from '@/views/Tools.vue'
import Vault from '@/views/Vault.vue'

export default {
  name: 'main-home',
  components: {
    MainSec,
    RoadMap,
    Chapters,
    Maps,
    Reports,
    Tools,
    Vault
  }
}
</script>
<style scoped>
.home {
  height: 100%;
}
</style>