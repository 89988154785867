// src/main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createVfm } from 'vue-final-modal'
import VueGtag from 'vue-gtag'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import directives from './directives/directives'  // Import the tooltip directive

const app = createApp(App);

const vfm = createVfm();

app.use(router);
app.use(vfm);

app.use(VueGtag, {
  property: {
    id: 'G-6N8Q8GQ89F'
  }
});

app.directive('tooltip', directives);

app.mount('#app');
