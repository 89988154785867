const REPORTS = [
    {
        title: 'VASyR 2023',
        year: '2023',
        image_url: '/images/reports/vasyr-2023.jpg',
        download_url: 'https://forms.office.com/e/PhSQQBAH4C'
    },
    {
        title: 'VASyR 2022',
        year: '2022',
        image_url: '/images/reports/vasyr-2022.jpg',
        download_url: '/vasyr/files/vasyr_reports/VASyR 2022.pdf'
    },
    {
        title: 'VASyR 2021',
        year: '2021',
        image_url: '/images/reports/vasyr-2021.jpg',
        download_url: '/vasyr/files/vasyr_reports/VASyR 2021.pdf'
    },
    {
        title: 'VASyR 2020',
        year: '2020',
        image_url: '/images/reports/vasyr-2020.jpg',
        download_url: '/vasyr/files/vasyr_reports/VASyR 2020.pdf'
    },
    {
        title: 'VASyR 2019',
        year: '2019',
        image_url: '/images/reports/vasyr-2019.jpg',
        download_url: '/vasyr/files/vasyr_reports/VASyR 2019 digital version.pdf'
    },
    {
        title: 'VASyR 2018',
        year: '2018',
        image_url: '/images/reports/vasyr-2018.jpg',
        download_url: '/vasyr/files/vasyr_reports/vasyr-2018.pdf'
    },
    {
        title: 'VASyR 2017',
        year: '2017',
        image_url: '/images/reports/vasyr-2017.jpg',
        download_url: '/vasyr/files/vasyr_reports/vasyr-2017.pdf'
    },
    {
        title: 'VASyR 2016',
        year: '2016',
        image_url: '/images/reports/vasyr-2016.jpg',
        download_url: '/vasyr/files/vasyr_reports/vasyr-2016.pdf'
    },
    {
        title: 'VASyR 2015',
        year: '2015',
        image_url: '/images/reports/vasyr-2015.jpg',
        download_url: '/vasyr/files/vasyr_reports/vasyr-2015.pdf'
    },
    {
        title: 'VASyR 2014',
        year: '2014',
        image_url: '/images/reports/vasyr-2014.jpg',
        download_url: '/vasyr/files/vasyr_reports/vasyr-2014.pdf'
    },
    {
        title: 'VASyR 2013',
        year: '2013',
        image_url: '/images/reports/vasyr-2013.jpg',
        download_url: '/vasyr/files/vasyr_reports/vasyr-2013.pdf'
    }
];

export default {
    REPORTS: REPORTS
}