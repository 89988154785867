import { createWebHashHistory, createRouter } from "vue-router";
import Home from './views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  }
];

/*
  This class handles the routes between pages if they exist
  currently only one route exists as the website is a one pager
  with different components
*/

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
